@media (min-width: 1250px) and (max-width: 1391px), (max-height: 899px) {
    .heading-respp-intro{
        scale: 0.85;
         margin: -48px 0px -50px -38px !important;
    }
    }

.intro-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: black;
    width: 100%;
}
.intro-img1{
    position: absolute;
    z-index: 1;
    left: 47%;
    scale: 0.85;
    top: 52px;
}
.intro-img2 {
    position: absolute;
    z-index: 1;
    left: 16%;
    transform: scale(0.85);
    top: -50px;
  }
  
.text-shadow {
    text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7)
}
.left-common-div-intro{
    margin-left: 90px;
    padding-left: 30px;
    padding-bottom: -50px;
}
.intro-card {
    display: flex;
    align-items: center;
    margin-top: 80px;
    border-radius: 10px;
    height: 290px;
    background: rgb(16 24 7 / 83%);
    box-shadow: -10px 10px 5px -5px rgba(146, 208, 80, 0.25);
    -webkit-box-shadow: -10px 10px 5px -5px rgba(146, 208, 80, 0.25);
    -moz-box-shadow: -10px 10px 5px -5px rgba(146, 208, 80, 0.25);
    transition: width 0.4s ease-in-out;
}
.intro-cards-upper-div{
    width: 100%;
    margin-top: 65px;
    /* padding-top: -55px; */
    padding-bottom: 121px;
    background-image: url('../..//assets/images/landing/intro-bg.jpeg');
    background-position: center;
    background-size: cover;

}
.intro-card-1-margin {
    width: 48vw;
    margin-top: 180px;
    margin-left: 200px;
}

.intro-card-2-margin {
    width: 49vw;
    margin-left: 35vw !important;
}

.int-card-1 {
    height: 85%;
    width: 80%;
}

.card-heading {
    color: white;
    font-weight: 700;
    margin-bottom: 22px;
}

.intro-card-parent-2 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.intro-card-parent {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.intro-img {
    height: 200px;
    width: 220px;
    margin-right: 20px;
}

.intro-p {
    padding: 0px 200px;
    text-align: center;
    font-size: 22px;
    margin-top: 30px;
}
.intro-divider {
    border: 1px solid #92D050;
    width: 19%;
    margin: 10px 0px 10px 45px;
  }
  
.intro-heading-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

@media (max-width: 1750px) {
    .intro-img1{
        left: 51%;
    }
    .intro-img2 {
        left: 14%;
      }
      .int-card-1 {
        width: 81% !important;
      }
}
@media (max-width: 1700px) {
    .intro-card-2-margin {
        width: 800px;
        margin-left: 500px !important;
    }
    .intro-img2 {
        left: 9%;
      }
}

@media (max-width: 1743px) {
    .intro-card-2-margin {
        width: 834px;
    }
    .intro-card-1-margin {
        width: 834px;
    }
}

@media (max-width: 1573px) {
    .intro-card-2-margin {
        width: 834px;
        margin-left: 400px !important;
    }
    .intro-img2 {
        left: 4%;
      }

    .intro-img1{
        left: 775px;
    }
    .intro-card-1-margin {
        width: 834px;
        margin-left: 100px;
    }

}

@media (max-width: 1351px), (max-height: 899px) {
    .scale-div {
      transform: scale(0.85);
      margin: -100px 0px -50px 0px;
    }
  }
  

@media (max-width: 1139px) {
    .intro-img1, .intro-img2{
        display: none;
    }
}

@media (max-width:1134px) {
    .left-common-div-intro{
        margin-left: 0px;
        padding-left: 0px;
    }
    .intro-divider {
    width: 90%;
    }    
}
@media (max-width: 1325px) {
    .intro-card-2-margin {
        width: 834px;
        margin-left: 400px !important;
    }
    .intro-img2 {
        left: 4%;
      }
}
@media (max-width: 1125px) {
    .intro-p {
        padding: 0px 20px;
        font-size: 20px;
    }

    .intro-card-2-margin {
        width: 100%;
        height: 100%;
        margin-left: 0px !important;
    }

    .intro-card-1-margin {
        width: 100%;
        height: 100%;
        margin-left: 0px !important;
    }

    .int-card-1 {
        height: 100%;
        width: 100%;
    }

    .intro-card {
        /* flex-direction: column; */
        /* width: 100vw; */
        padding: 20px;
    }
}

@media (max-width: 1015px) {
    .intro-p {
        padding: 0px 0px;
        font-size: 20px;
    }

    .intro-card {
        gap: 20px;
        flex-direction: column;
        /* width: 100vw; */
        padding: 20px;
        text-align: center;
    }
}

@media (max-width:900px) {
    .int-card-1 {
        height: 100%;
        width: 100%;
    }
}

@media (max-width: 700px) {
    .intro-p {
        padding: 0px 00px;
        font-size: 16px;
    }

    .intro-h {
        font-size: 35px;
    }

    .card-text {
        font-size: 16px;
    }
}


@media (max-width: 500px) {
    .intro-p {
        padding: 0px 0px;
        font-size: 15px;
    }

    .intro-h {
        font-size: 30px;
    }
.card-heading{
    font-size: 26px;
}
    .card-text {
        font-size: 14px;
    }
}