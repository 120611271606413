input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}


:root {

    /* Override all progress bar colors with a linear gradient */
    --toastify-color-progress-light: linear-gradient(0deg, rgba(91,119,62,1) 0%, rgba(146,208,80,1) 100%) !important; /* For light theme */
    --toastify-color-progress-dark: linear-gradient(0deg, rgba(91,119,62,1) 0%, rgba(146,208,80,1) 100%) !important;  /* For dark theme */
    --toastify-color-progress-info: linear-gradient(0deg, rgba(91,119,62,1) 0%, rgba(146,208,80,1) 100%) !important;
    --toastify-color-progress-success: linear-gradient(0deg, rgba(91,119,62,1) 0%, rgba(146,208,80,1) 100%) !important;
    --toastify-color-progress-warning: linear-gradient(0deg, rgba(91,119,62,1) 0%, rgba(146,208,80,1) 100%) !important;
    --toastify-color-progress-error: linear-gradient(0deg, rgb(255, 237, 237) 0%, rgb(208, 80, 80) 100%) !important;
  
    /* Optional: Set progress bar opacity */
    --toastify-color-progress-bgo: .2;
  }
  
  /* Custom progress bar color for all toast types */
  .toastify-progress {
    background: linear-gradient(0deg, rgba(91,119,62,1) 0%, rgba(146,208,80,1) 100%) !important; /* Linear gradient */
  }
  
  .Toastify__toast--error {
    background: rgb(61, 10, 10) !important;
    color: white !important;
  }
  


/* Bakbak One  removerdglobally*/
@font-face {
    font-family: 'Aptos';
    src: url('../../assets/font/fonnts.com-aptos-light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Aptos';
    src: url('../../assets/font/fonnts.com-aptos-light-italic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Aptos';
    src: url('../../assets/font/fonnts.com-aptos.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Aptos';
    src: url('../../assets/font/fonnts.com-aptos-italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Aptos';
    src: url('../../assets/font/fonnts.com-aptos-semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Aptos';
    src: url('../../assets/font/fonnts.com-aptos-bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Aptos';
    src: url('../../assets/font/fonnts.com-aptos-black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Aptos';
    src: url('../../assets/font/fonnts.com-aptos-black-italic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

.landing-main-div {
    /* scale: 1; */
}

.f-w-100 {
    font-weight: 100;
}

.f-w-200 {
    font-weight: 200;
}

.f-w-300 {
    font-weight: 300;
}

.f-w-400 {
    font-weight: 400;
}

.f-w-500 {
    font-weight: 500;
}

.f-w-600 {
    font-weight: 600;
}

.f-w-700 {
    font-weight: 700;
}

.f-w-800 {
    font-weight: 800;
}

.f-w-900 {
    font-weight: 900;
}

body {
    font-family: 'Aptos', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
    font-family: 'Aptos', sans-serif;
}

@media (min-width: 1250px) and (max-width: 1391px),
(max-height: 899px) {
    .heading-resp {
        scale: 0.85;
        margin: -48px 0px -111px -142px !important;
    }
}

.text-shadow {
    text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.7);
}

.upper-head-div {
    margin-bottom: 30px;
    display: flex;
}

.upper-head-div h2 {
    font-size: 30px;
}

.intro-outer-div {
    padding: 70px 0px;
    background-color: black;
}

.countdown-card {
    display: flex;
}

.left-common-div {
    margin-left: -30px;
    border-left: 5px solid #92D050;
    padding-left: 10xpx;
}
.toast-message {
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(51, 51, 51, 1) 100%) !important;
    color: white !important;
    font-size: 18px !important;
    font-weight: 400;
    min-width: 15vw !important;
    padding: 25px 18px !important;
    border: 1px solid #92D050 !important;
   
    -webkit-box-shadow: -100px 0px 99px -23px rgba(146,208,80,0.55) !important;
    -moz-box-shadow: -100px 0px 99px -23px rgba(146,208,80,0.55) !important;
    box-shadow: -100px 0px 99px -23px rgba(146,208,80,0.55) !important;

    border-right: none !important;
    border-bottom: none !important;
}
.countdown-card-main {
    /* background-image: url('../../assets/images/landing/timelaps-bg.jpeg'); */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 50px;
    background-color: #0000007a;
}

.timer-box {
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 7px 5px 2px rgba(146, 208, 80, 0.52);
    -webkit-box-shadow: 1px 7px 5px 2px rgba(146, 208, 80, 0.52);
    -moz-box-shadow: 1px 7px 5px 2px rgba(146, 208, 80, 0.52);
}

.time {
    font-size: 50px;
    font-weight: 700;
    padding: 30px;
    margin: 10px;
    border-radius: 10px;
    color: white;
    min-width: 100px;
    background-color: black;
    box-shadow: 1px 7px 5px 0px rgba(146, 208, 80, 0.15);
    -webkit-box-shadow: 1px 7px 5px 0px rgba(146, 208, 80, 0.15);
    -moz-box-shadow: 1px 7px 5px 0px rgba(146, 208, 80, 0.15);
}

.button-LH {
    height: 50px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #D9B46A;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(51, 51, 51, 1) 100%);
    color: #000;
    font-weight: bold;
    width: 80%;
    cursor: pointer;
    margin-top: 20px;
    transition: 1.4s;
    font-family: 'Aptos';
}

.button-LH:hover {
    transition: .4s;
    background: linear-gradient(180deg, rgb(123, 123, 123) 0%, rgba(0, 0, 0, 1) 100%);
    color: #000;
}

.button-div-LH {
    display: flex;
    gap: 15px;
}

.extra-border {
    border: 2px solid #92D050 !important;
}

.button-text-LH {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    transition: .4s;
}

.button-text-LH:hover {
    color: #D9B46A;
    transition: .4s;
}

.button:hover {
    background: linear-gradient(180deg, rgba(153, 101, 19, 1) 0%, rgb(226, 190, 118) 100%);
    transition: .4s;
}

.button {
    height: 50px;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #D9B46A;
    background-color: #D9B46A;
    color: #000;
    background: linear-gradient(180deg, rgba(153, 101, 19, 1) 0%, rgba(214, 163, 59, 1) 100%);
    font-weight: bold;
    width: 80%;
    cursor: pointer;
    margin-top: 20px;
    transition: .4s !important;
    font-family: 'Aptos';
}

.sub-heading-LH {
    color: #92D050 !important;
    width: 700px;
}

.heading2-base {
    margin-right: 14px;
    font-size: 24px;
    /* Example base font size */
}

.heading2-tokens {
    color: rgb(217 172 101);
    font-size: 24px;
    /* Example font size for REM TOKENS */
}

/* style={{display:"flex",alignItems:"center",gap:"10px",color:"white"}} */
.countdown-heading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.countdown {
    display: flex;
    align-items: center;
    font-size: 36px;
    /* Font size for the countdown timer */
    font-weight: bold;
    /* Font weight for the countdown timer */
    color: #92D050;
    /* Color for the countdown timer */
    font-family: 'Aptos';
    /* Example font family */
}


.main-heading {
    font-size: 52px;
    text-align: left;
}

.sub-heading-LH {
    width: 700px
}

.subheading-div {
    width: 45%;
    padding-top: 10px;
}

@media (max-width:1360px) {
    .subheading-div {
        width: 65%;
    }
}

@media (max-height:1313px) {
    .main-heading {
        font-size: 46px;
    }
}

@media (max-width: 1200px) {


    .main-heading {
        font-size: 43px;

    }
}

@media (max-width: 1050px) {
    .main-heading {
        text-align: center;
    }
}

@media (max-width:1134px) {
    .left-common-div {
        border: none;
    }

    .subheading-div {
        width: auto;
        margin: 0px;
    }

    .intro-main-heading {
        text-align: center;
    }

    .intro-subheading {
        text-align: center;
    }

    .upper-head-div {
        text-align: center;
        align-items: center;
        justify-content: center;
    }

}

@media (max-width: 1130px) {
    .countdown-card-main {
        display: block;
    }

    .countdown {
        justify-content: center;
        margin-top: 10px;
    }
}

@media (max-width: 1025px) {
    .resp {
        display: none;
    }
}

@media (max-width: 1024px) {
    .form-container {
        padding: 40px 50px;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        border-radius: 10px;
        width: 100%;
        text-align: center;
        font-family: 'Aptos';
        color: #fff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        grid-template-columns: 1fr;
        gap: 0;
        padding: 40px 20px;
    }

    .intro-outer-div {
        padding: 20px;
    }
}

@media (max-width: 900px) {
    .countdown-card-main {
        padding: 0px;
    }
}

@media (max-width: 750px) {
    .main-heading {
        font-size: 40px;
        text-align: center;
    }

    .upper-head-div h2 {
        font-size: 25px;
    }
}

@media (max-width:616px) {
    .sub-heading-LH {
        width: 100%
    }
}

@media (max-height: 720px) or (max-width: 1150px) {
    .countdown-card-main {
        padding-top: 10px;
        margin: 100px 0px 0px 0px;
    }

}

@media (max-height: 525px) {
    .countdown-card-main {
        margin: 190px 0px 0px 0px;
    }
}

@media (max-width: 600px) {

    .heading2-base,
    .heading2-tokens {
        font-size: 20px;
        text-align: center;
    }

    .main-heading {
        font-size: 35px;
        text-align: center;
    }

    .upper-head-div {
        flex-direction: column;
    }

    .sub-heading-LH {
        font-size: 20px;
    }

    .countdown {
        scale: 0.7;
    }

    .countdown-card-main {
        /* scale: 0.9; */
        padding-top: 10px;
        margin: 50px 0px 40px 0px;
    }

}

@media (max-width: 500px) {
    .button-div-LH {
        height: 150px;
        flex-direction: column;
        gap: 0px;
    }

    .upper-head-div h2 {
        font-size: 17px;
    }

    .main-heading {
        font-size: 30px;
        text-align: center;
    }
}

@media (max-width: 450px) {

    .sub-heading-LH {
        font-size: 17px;
    }
}

@media (max-width: 60px) {
    .sub-heading {
        text-align: center !important;
    }
}