@font-face {
    font-family: 'Aptos';
    src: url('../../assets/font/fonnts.com-aptos-light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.dropdown-option {
    background-color: rgba(56, 56, 56, 0.8);
    color: rgb(214, 180, 126) !important;
    font-size: 16px;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}
.button-LH-kyc:focus{
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(51, 51, 51, 1) 100%) !important;
}
.color {
    /* color: #92D050 */
    color: rgb(186, 186, 186)
}

input[type="checkbox"]:checked {
    background-color: #92D050 !important;
}

.homediv {
    position: absolute;
    left: 200px;
    top: 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 35px;
    align-items: center;
    /* Corrected from "alignitem" */
    justify-content: center;
    margin-bottom: 25px;
    border-radius: 15px;
    width: 176px !important;
    background-color: rgba(38, 38, 38, 0.943);
    height: 305px;
}

.toast-message {
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(51, 51, 51, 1) 100%) !important;
    color: white !important;
    font-size: 18px !important;
    font-weight: 400;
    min-width: 15vw !important;
    padding: 25px 18px !important;
    border: 1px solid #92D050 !important;

    -webkit-box-shadow: -100px 0px 99px -23px rgba(146, 208, 80, 0.55) !important;
    -moz-box-shadow: -100px 0px 99px -23px rgba(146, 208, 80, 0.55) !important;
    box-shadow: -100px 0px 99px -23px rgba(146, 208, 80, 0.55) !important;

    border-right: none !important;
    border-bottom: none !important;
}

.section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #959595;
    color: rgb(241, 188, 43);
}

.buttpn-text {
    font-size: 18px;
    height: 25px;
}

.marginB {
    margin-bottom: 35px;
}

.main-border-top {
    border-top: 1px solid rgb(241, 188, 43);
}

/* Form Styles */
.form-container {
    width: 100%;
    max-width: 520px;
    padding: 0px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: rgba(38, 38, 38, 0.943);
    color: rgb(241, 188, 43);
    text-align: center;
}

.form-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.button-container {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0px 10px;
}

/* Form Input Styles */
.formInput {
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    width: 100%;
}

/* Button Styles */
.button {
    width: 320px;
    /* Adjust width as needed */
    font-size: 16px;
    /* Adjust font size as needed */
    height: 66px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: rgb(241, 188, 43);
    color: black;
    border: none;
    border-radius: 5px;
    /* font-weight: bold; */
    transition: background-color 0.3s;
}

.button:hover {
    background-color: darkorange;
}

.file-input {
    margin-bottom: 15px;
}

.custom-file-input {
    position: relative;
    overflow: hidden;
    display: inline-block;
    background-color: rgb(241, 188, 43);
    color: black;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.custom-file-input input[type="file"] {
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.custom-file-input:hover {
    background-color: darkorange;
}


/* Placeholder Styles */
::placeholder {
    color: rgb(241, 188, 43);
    opacity: 1;
    /* Set opacity to 1 if you want the placeholder text to be fully opaque */
}

/* Firefox */
input::-moz-placeholder {
    color: rgb(241, 188, 43);
    opacity: 1;
}

/* Internet Explorer 10-11 */
input:-ms-input-placeholder {
    color: rgb(241, 188, 43);
    opacity: 1;
}

/* Microsoft Edge */
input::-ms-input-placeholder {
    color: rgb(241, 188, 43);
    opacity: 1;
}

/* Chrome, Safari, and Opera */
input::placeholder {
    color: rgb(241, 188, 43);
    opacity: 1;
}


@media (max-width:400px) {
    .recap {
        scale: .7;
    }

}

@media (max-width:1400px) {
    .homediv {
        left: 80px ;
    }
}
@media (max-width:1155px) {
    .homediv {
        left: 40px ;
    }
}
@media (max-width:1024px) {
    .homediv {
        display: none;
    }
}

@keyframes flip {
    0% {
        transform: perspective(1000px) rotateY(0deg);
    }

    50% {
        transform: perspective(1000px) rotateY(180deg);
    }

    100% {
        transform: perspective(1000px) rotateY(360deg);
    }
}

.flip-animation {
    /* animation: flip 3s infinite; */
    display: inline-block;
    height: 100px;
    width: 133px;
}

/*  */
/* HTML: <div class="loader"></div> */
.loader {
    width: 12px;
    aspect-ratio: 1;
    border-radius: 50%;
    clip-path: inset(-45px);
    box-shadow: -60px 15px, -60px 15px, -60px 15px;
    transform: translateY(-15px);
    animation: l19 1.6s infinite linear;
}

@keyframes l19 {
    16.67% {
        box-shadow: -60px 15px, -60px 15px, 19px 15px
    }

    33.33% {
        box-shadow: -60px 15px, 0px 15px, 19px 15px
    }

    40%,
    60% {
        box-shadow: -19px 15px, 0px 15px, 19px 15px
    }

    66.67% {
        box-shadow: -19px 15px, 0px 15px, 60px 15px
    }

    83.33% {
        box-shadow: -19px 15px, 60px 15px, 60px 15px
    }

    100% {
        box-shadow: 60px 15px, 60px 15px, 60px 15px
    }
}


/* Customize the scrollbar for webkit browsers (Chrome, Safari) */
.formInput::-webkit-scrollbar {
    width: 12px;
}

.formInput::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.formInput::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.formInput::-webkit-scrollbar-thumb:hover {
    background: #92D050;
}

/* Customize the scrollbar for Firefox */
.formInput {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

/* Track */
.formInput::-moz-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
.formInput::-moz-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

/* Handle on hover */
.formInput::-moz-scrollbar-thumb:hover {
    background: #555;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.password-icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.inputContainer {
    position: relative;
    width: 100%;
}