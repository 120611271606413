@font-face {
  font-family: 'Aptos';
  src: url('../../assets/font/fonnts.com-aptos-light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Aptos';
}

.td-main {
  width: 100%;
  padding: 100px 0px 0px 105px;
}



.title-style {
  position: relative;
  z-index: 1;
  margin-left: -9px;
  font-weight: 700 !important;
  font-size: 27px !important;
  margin-top: -10px;
  margin-bottom: 0px;
  text-align: left;
}

.td-cards-main {
  display: flex;
  margin-left: -50px;
  margin-top: 40px;
  flex-wrap: wrap;
  padding: 50px 0px 0px 0px;
}

/* 
.img-div {
  background: url('../../assets/images/landing/drivers-bg.jpeg');
  background-size: cover;
  background-position: right;
  width: 100%;
} */

.img-div {
  background: url('../../assets/images/landing/drivers-bg.jpeg');
  background-size: 100%;
  background-position: right;
  background-repeat: no-repeat;
  width: 100%;
}

.driver-card {
  padding-right: 15px;
  padding-left: 15px;
  margin: 20px -10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 270px;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.card {
  width: 100%;
  padding-top: 100%;
  /* This makes the card a square */
  position: relative;
  min-height: 200px;
  /* Adjust as needed */
  background-color: black;
  background: #f5f5f5;
  /* Placeholder background color */
  padding: 20px;
  /* Remove the border */
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .4s;
}


.card-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgb(20, 20, 20);
  padding: 20px 0px 10px 40px;
  align-items: center;
  transition: .6s;
  overflow: hidden;
}

.card-content:hover {
  background-color: rgb(39, 39, 39);
  transition: .6s;
}

.card-content::before {
  content: '';
  position: absolute;
  left: 20px;
  top: 7%;
  width: 6px;
  height: 85%;
  background-color: #92D050;
  transition: background-color 0.2s ease, height 0.2s ease, width 0.2s ease;
}

.card-content:hover::before {
  background-color: #a5e164;
  height: 87%;
  width: 9px;
}


.text-shadow {
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7);
}

.tf-step {
  background: var(--primary-color8);
  padding: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.step-title {
  width: 100%;
}

.step-title .sub-number {
  font-family: 'Aptos';
  font-weight: 400;
  font-size: 80px;
  color: rgb(80, 170, 80);
  opacity: 0.3;
}

.step-title h3 {
  font-family: 'Aptos';
  font-weight: 400;
  font-size: 24px;
  padding-left: 15px;
  text-transform: uppercase;
  color: var(--primary-color3);
}

.tf-step p {
  font-family: 'Aptos';
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: var(--primary-color);
}

/* Define the base styles for the paragraph */
.responsive-paragraph {
  padding: 0px 6px;
  color: white !important;
  font-weight: 500 !important;
  line-height: 1.2;
  font-size: 20px;
  /* Default font size */
  text-align: left;
  /* Default text alignment */
}

/* Responsive styles */
@media (max-width: 1570px) {
  .responsive-paragraph {
    font-size: 18px !important;
    line-height: 1.2 !important;
  }

}

@media (min-width:1529px) {
  .td-cards-main {
    width: 90%;
  }

  .img-div {
    /* background: url('../../assets/images/landing/drivers-bg.jpeg');
    background-size: cover;
    background-position: right; */
  }

  .td-cards-main {
    background-image: none;
  }
}

@media (min-width: 1250px) and (max-width:1500px) {
  .td-cards-main {
    margin: -50px -150px -110px -215px;
    scale: 0.8;
  }
}

@media (max-width:1380px) {
  .title-style {
    font-size: 20px !important;
  }

}

@media (max-width: 1249px) {
  .responsive-paragraph {
    font-size: 23px !important;
    text-align: center;
    width: 70%;
  }

  .td-cards-main {
    margin-left: 0px;
  }

  .step-title h3 {
    font-family: 'Aptos';
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    color: var(--primary-color3);
  }

  .title-style {
    font-size: 22px !important;
  }
}

@media (max-width: 1250px) {
  .td-main {
    padding: 100px 0px 0px 0px;
  }

  .card {
    border-right: 1px solid #92d0509e;
  }
}

@media (max-width:1130px) {
  .driver-heading {
    text-align: center;
    font-size: 34px;
  }
}

@media (max-width: 750px) {
  .responsive-paragraph {
    width: 100%;
    font-size: 17px !important;

  }
}