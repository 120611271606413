:root {

  /* Override all progress bar colors with a linear gradient */
  --toastify-color-progress-light: linear-gradient(0deg, rgba(91, 119, 62, 1) 0%, rgba(146, 208, 80, 1) 100%) !important;
  /* For light theme */
  --toastify-color-progress-dark: linear-gradient(0deg, rgba(91, 119, 62, 1) 0%, rgba(146, 208, 80, 1) 100%) !important;
  /* For dark theme */
  --toastify-color-progress-info: linear-gradient(0deg, rgba(91, 119, 62, 1) 0%, rgba(146, 208, 80, 1) 100%) !important;
  --toastify-color-progress-success: linear-gradient(0deg, rgba(91, 119, 62, 1) 0%, rgba(146, 208, 80, 1) 100%) !important;
  --toastify-color-progress-warning: linear-gradient(0deg, rgba(91, 119, 62, 1) 0%, rgba(146, 208, 80, 1) 100%) !important;
  --toastify-color-progress-error: linear-gradient(0deg, rgb(255, 237, 237) 0%, rgb(208, 80, 80) 100%) !important;

  /* Optional: Set progress bar opacity */
  --toastify-color-progress-bgo: .2;
}

/* Custom progress bar color for all toast types */
.toastify-progress {
  background: linear-gradient(0deg, rgba(91, 119, 62, 1) 0%, rgba(146, 208, 80, 1) 100%) !important;
  /* Linear gradient */
}

.Toastify__toast--error {
  background: rgb(61, 10, 10) !important;
  color: white !important;
}

.Toastify__close-button,
.Toastify__close-button--light {
  color: white !important;
  padding-right: 9px !important;
}


.swap-input {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

input {
  width: 100%;
  padding-right: 50px;
  /* Add space for the button inside */
}

.toast-message {
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(51, 51, 51, 1) 100%) !important;
  color: white !important;
  font-size: 18px !important;
  font-weight: 400;
  min-width: 15vw !important;
  padding: 25px 18px !important;
  border: 1px solid #92D050 !important;

  -webkit-box-shadow: -100px 0px 99px -23px rgba(146, 208, 80, 0.55) !important;
  -moz-box-shadow: -100px 0px 99px -23px rgba(146, 208, 80, 0.55) !important;
  box-shadow: -100px 0px 99px -23px rgba(146, 208, 80, 0.55) !important;

  border-right: none !important;
  border-bottom: none !important;
}

.max-button {
  position: absolute;
  right: 10px;
  top: 52%;
  transform: translateY(-50%);
  height: 30px;
  padding: 0 10px;
  border: none;
  background-color: #92D050;
  color: #000;
  background: linear-gradient(180deg, rgba(153, 101, 19, 1) 0%, rgba(214, 163, 59, 1) 100%);
  font-weight: bold;
  cursor: pointer;
  font-family: 'Aptos', sans-serif;
}

.max-button:hover {
  background: linear-gradient(180deg, rgba(153, 101, 19, 1) 0%, rgb(226, 190, 118) 100%);
  transition: .4s;
}

.swap-button {
  height: 50px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #92D050;
  color: #000;
  background: rgb(153, 101, 19);
  background: linear-gradient(180deg, rgba(153, 101, 19, 1) 0%, rgba(214, 163, 59, 1) 100%);
  font-weight: bold;
  cursor: pointer;
  font-family: 'Aptos', sans-serif;
}

.swap-button:hover {
  background: linear-gradient(180deg, rgba(153, 101, 19, 1) 0%, rgb(226, 190, 118) 100%);
  transition: .4s;
}

@media (max-height: 790px) {
  .scaling {
    margin-top: 20px;
    scale: 0.9;
    margin-top: 60px !important;
  }
}

@media (max-height: 750px) {
  .scaling {
    scale: 0.85;
    margin-top: 60px !important;
  }
}

@media (max-height: 700px) {
  .scaling {
    scale: 0.8;
  }
}


@media (max-height: 650px) {
  .scaling {
    scale: 0.76;
    margin-top: -0px !important;
  }
}

@media (max-width: 530px) {
  .swap-input {
    flex-direction: column;
    width: 100%;
  }

  .swap-button {
    width: 200px !important;
    margin: -20px 0 20px 0 !important;
  }

  .max-button {
    top: auto;
    bottom: 10px;
    transform: none;
  }

  .price {
    font-size: 18px !important;
    margin: 0 -32px;
  }

  .buy-tokens-button {
    width: 200px !important;
  }
}